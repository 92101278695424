import { createAPIAction } from 'store/utils/createAPIAction';
import { createAction } from 'store/utils/createAction';
import { ThunkDispatch } from 'redux-thunk';
import { Car } from 'entities/Car';
import { ConsumerAddress } from 'entities/ConsumerAddress';
import { AppointmentForCards } from 'entities/AppointmentForCards';
import { CarsGroup } from 'entities/CarsGroup';
import { PaymentMethod } from 'entities/PaymentMethod';
import { VwoExperimentViewed } from 'entities/vwoExperimentViewed';
import { isArray } from 'lodash';

const serviceName = 'consumer';

export const getCar = createAPIAction(
  `${serviceName}/GET_CAR`,
  (consumerCarId: number) => ({
    endpoint: `/consumer-service/consumer/cars/${consumerCarId}`,
    method: 'GET',
  })
);

export const setCar = createAction(`${serviceName}/SET_CAR`);

export const updateCarDetails = createAPIAction(
  `${serviceName}/UPDATE_CAR_DETAILS`,
  (data: any) => ({
    endpoint: `/consumer-service/consumer/cars/${data.carId}`,
    method: 'PUT',
    data,
  })
);

export const validateVinOrPlate = createAPIAction(
  `${serviceName}/VALIDATE_VIN_OR_PLATE`,
  ({ vin, plate, state }: { vin?: string; plate?: string; state?: string }) => {
    const params = [];
    if (vin?.length) params.push(`vin=${vin}`);
    if (plate?.length) params.push(`plate=${plate}`);
    if (state?.length) params.push(`state=${state}`);
    return {
      endpoint: `/consumer-service/consumer/cars/validate-vin-plate?${params.join(
        '&'
      )}`,
      method: 'GET',
    };
  }
);

export const updateVinAndQuote = createAPIAction(
  `${serviceName}/UPDATE_VIN_AND_QUOTE`,
  (
    data: {
      carId: string;
      vin: string;
      plateNumber: string;
      plateNumberState: string;
    },
    refNum: string
  ) => ({
    endpoint: `/consumer-service/consumer/cars/${data.carId}${
      refNum ? `?referenceNum=${refNum}` : ''
    }`,
    method: 'PUT',
    data,
  })
);

export const addNewCar = createAPIAction(
  `${serviceName}/ADD_NEW_CAR`,
  (data: { carDetails: Car[] }) => ({
    endpoint: '/consumer-service/consumer/cars',
    method: 'POST',
    data,
  })
);

export const getCars = createAPIAction(`${serviceName}/GET_CARS`, () => ({
  endpoint: '/consumer-service/consumer/cars',
  method: 'GET',
}));

export const getCarsAggregatedInfo = createAPIAction(
  `${serviceName}/GET_CARS_AGGREGATED_INFO`,
  ({ archived = false }: { archived?: boolean }) => ({
    endpoint: `/consumer-service/consumer/cars/aggregatedInfo?archived=${archived}`,
    method: 'GET',
  })
);

export const getCarsFiltered = createAPIAction<
  [
    {
      page?: number;
      size?: number;
      sort?: string;
      data?: {
        searchFilter?: string;
        carYears?: number[];
        makes?: string[];
        models?: string[];
        mileageIntervals?: { min: number; max: number }[];
        archived?: boolean;
      };
    },
  ]
>(
  `${serviceName}/GET_CARS_FILTERED`,
  ({ page = 0, size = 25, sort = 'carMake,asc', data }) => ({
    endpoint: `/consumer-service/consumer/cars/filter?page=${page}&size=${size}&sort=${sort}`,
    method: 'POST',
    data,
  })
);

export const addCarWithRefresh =
  (data: { carDetails: Car[] }) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    await dispatch(addNewCar(data));

    // backend doesn't return model/make names with POST
    // so we need to update the list of cars with GET
    await dispatch(getCars());
  };

export const getAppointments = createAPIAction<[], any, AppointmentForCards[]>(
  `${serviceName}/GET_APPOINTMENTS`,
  () => ({
    endpoint: '/repair-service/consumer/appointments',
  })
);

export const getAppointmentsHistory = createAPIAction(
  `${serviceName}/GET_APPOINTMENTS_HISTORY`,
  () => ({
    endpoint: '/repair-service/consumer/appointments/historical',
    method: 'GET',
  })
);

export const getAppointmentsHistoryV2 = createAPIAction(
  `${serviceName}/GET_APPOINTMENTS_HISTORY_V2`,
  ({
    nextPage,
    size,
    consumerCarId,
  }: {
    nextPage: number;
    size: number;
    consumerCarId?: number;
  }) => {
    let endpoint = `/repair-service/consumer/appointments/v2/historical?page=${nextPage}&size=${size}`;
    if (consumerCarId) {
      endpoint += `&consumerCarId=${consumerCarId}`;
    }
    return {
      endpoint,
      method: 'GET',
    };
  }
);

export const getAddresses = createAPIAction(
  `${serviceName}/GET_CONSUMER_ADDRESSES`,
  () => ({
    endpoint: '/consumer-service/consumer/addresses',
  })
);

export const addAddress = createAPIAction(
  `${serviceName}/ADD_CONSUMER_ADDRESS`,
  (data: ConsumerAddress) => ({
    endpoint: '/consumer-service/consumer/addresses',
    method: 'POST',
    data,
  })
);

export const deleteAddress = createAPIAction(
  `${serviceName}/DELETE_CUSTOMER_ADDRESS`,
  (id: number) => ({
    endpoint: `/consumer-service/consumer/addresses/${id}`,
    method: 'DELETE',
  })
);

export const setDeliveryAddress = createAction(
  `${serviceName}/SET_DELIVERY_ADDRESS`
);

export const setConsumerSelectedAddress = createAction(
  `${serviceName}/SET_CONSUMER_SELECTED_ADDRESS`
);

export const updateConsumerWithAnonymousQuote = createAPIAction(
  `${serviceName}/UPDATE_CONSUMER`,
  ({ email, repairRequestId, data }) => {
    return {
      endpoint: `/consumer-service/consumer/${email}/repair-request/${repairRequestId}`,
      method: 'PUT',
      data,
    };
  }
);

export const getGroups = createAPIAction<[], any, CarsGroup[]>(
  `${serviceName}/GET_GROUPS`,
  () => {
    return {
      endpoint: '/consumer-service/consumer/cars/group',
      method: 'GET',
    };
  }
);

export const createGroup = createAPIAction<
  [Partial<CarsGroup>],
  any,
  CarsGroup
>(`${serviceName}/CREATE_GROUP`, (data) => {
  return {
    endpoint: '/consumer-service/consumer/cars/group',
    method: 'POST',
    data,
  };
});

export const updateGroup = createAPIAction<
  [{ id: string; label: string }],
  any,
  CarsGroup
>(`${serviceName}/UPDATE_GROUP`, (data) => {
  return {
    endpoint: `/consumer-service/consumer/cars/group/${data.id}`,
    method: 'PUT',
    data,
  };
});

export const deleteGroup = createAPIAction<[{ id: string }], any, null>(
  `${serviceName}/DELETE_GROUP`,
  (data) => {
    return {
      endpoint: `/consumer-service/consumer/cars/group/${data.id}`,
      method: 'DELETE',
      data,
    };
  }
);

export const updateGroups = createAPIAction<
  [Partial<CarsGroup>[]],
  any,
  { groups: CarsGroup[] }
>(`${serviceName}/UPDATE_GROUPS`, (groups) => {
  return {
    endpoint: '/consumer-service/consumer/cars/group',
    method: 'PUT',
    data: { groups },
  };
});

export const deleteGroupFromCar = createAPIAction<
  [{ groupId: string; carId: number }],
  any,
  null
>(`${serviceName}/DELETE_GROUP`, (data) => {
  return {
    endpoint: `/consumer-service/consumer/cars/${data.carId}/group/${data.groupId}`,
    method: 'DELETE',
    data,
  };
});

export const getPaymentMethods = createAPIAction<
  [{ consumerId?: number; referenceNum?: string }],
  any,
  PaymentMethod[]
>(`${serviceName}/GET_PAYMENT_METHODS`, (data) => {
  return {
    endpoint: `/repair-service/consumer/${data.consumerId}/payment/method${
      data.referenceNum ? `?referenceNum=${data.referenceNum}` : ''
    }`,
    method: 'GET',
  };
});

export const deactivatePaymentMethod = createAPIAction<
  [{ consumerId: number; paymentMethodId: string }],
  any,
  null
>(`${serviceName}/DEACTIVATE_PAYMENT_METHOD`, (data) => {
  return {
    endpoint: `/repair-service/consumer/${data.consumerId}/payment/method/${data.paymentMethodId}`,
    method: 'PUT',
  };
});

export const updateDefaultPaymentMethod = createAPIAction<
  [{ consumerId?: number; paymentMethodId: string }],
  any,
  null
>(`${serviceName}/UPDATE_DEFAULT_PAYMENT_METHOD`, (data) => {
  return {
    endpoint: `/repair-service/consumer/${data.consumerId}/payment/method/${data.paymentMethodId}/default`,
    method: 'PUT',
  };
});

export const addPaymentMethod = createAPIAction<
  [
    {
      consumerId: number;
      defaultMethod: boolean;
      token: string;
      active: boolean;
      firstName?: string;
      lastName?: string;
      methodType?: string;
      referenceNum?: string;
      path?: string;
    },
  ],
  any,
  null
>(`${serviceName}/ADD_PAYMENT_METHOD`, (data) => {
  return {
    endpoint: `/repair-service/consumer/${data.consumerId}/payment/method`,
    method: 'POST',
    data,
  };
});

export const addVwoExperimentViewed = createAction<VwoExperimentViewed>(
  `${serviceName}/ADD_VWO_EXPERIMENT_VIEWED`
);

export const getVwoExperimentsViewed = createAPIAction<
  [{ consumerId: number }],
  any,
  VwoExperimentViewed[]
>(`${serviceName}/GET_VWO_EXPERIMENTS_VIEWED`, (data) => {
  return {
    endpoint: `/consumer-service/consumer/log/${data.consumerId}`,
    method: 'GET',
  };
});

export const submitVwoExperimentsViewed = createAPIAction<
  [{ consumerId: number; vwoTestData: string }],
  any,
  null
>(`${serviceName}/SUBMIT_VWO_EXPERIMENTS_VIEWED`, (data) => {
  return {
    endpoint: `/consumer-service/consumer/log/${data.consumerId}`,
    method: 'PUT',
    data,
  };
});

export const getCredits = createAPIAction<
  [{ status: string | string[]; expired: boolean }],
  any,
  null
>(`${serviceName}/GET_CREDITS`, ({ status, expired }) => {
  let statusPath: string;
  if (isArray(status)) {
    statusPath = status.map((s) => `statuses=${s}`).join('&');
  } else {
    statusPath = `statuses=${status}`;
  }

  return {
    endpoint: `/repair-service/consumer/tokens?${statusPath}&expired=${expired}`,
    method: 'GET',
  };
});

export const setGroups = createAction(`${serviceName}/SET_GROUPS`);
