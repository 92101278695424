import numeral from 'numeral';
import moment from 'moment';
import { get, isNil } from 'lodash';

export const commaFormat = (val: string | number) => numeral(val).format('0,0');
export const distanceFormat = (val: string | number) =>
  `${numeral(val).format('0,0[.]0')} mi`;
export const toMoneySansSign = (val: string | number) =>
  `${numeral(val).format('0,0[.]00')}`;
export const usMoneyBaseFormat = (val: string | number, format: string) =>
  `${Number(val) < 0 ? '-' : ''}$${numeral(val)
    .format(format)
    .replace('-', '')}`;
export const usMoneyFormat = (val: string | number) =>
  usMoneyBaseFormat(val, '0,0[.]00');
export const usMoneyFormatWithCents = (val: string | number) =>
  usMoneyBaseFormat(val, '0,0.00');
export const usMoneyFormatNoCents = (val: string | number) =>
  usMoneyBaseFormat(val, '0,0');
export const usMoneyInCents = (val: string | number) => {
  if (val == null) return val;
  const valString = val.toString();

  // EVEN DOLLAR AMOUNTS WON'T WORK WITH THE TOMONEYSANSSIGN FORMATTER
  if (valString.indexOf('.') === -1) {
    return parseInt(`${val}00`, 10);
  }

  // Fixes $42.5 situation not enough cents
  if (valString.match(/\.\d$/g)) {
    return parseInt(`${valString.replace('.', '')}0`, 10);
  }

  return parseInt(valString.replace('.', ''), 10);
};
export const shortDate = (val: string | number) =>
  `${moment(val).format('M/D/YY')}`;
export const longDate = (val: string | number) =>
  `${moment(val).format('M/D/YYYY')}`;
export const monthDay = (val: string | number) =>
  `${moment(val).format('MMMM D')}`;
export const serverDate = (val: string | number) =>
  `${moment(val).format('YYYY-MM-DD')}`;
export const numberKFormat = (val: string | number) => {
  const numberShortened = (Number(val) / 1000).toFixed(1);
  if (numberShortened.substring(numberShortened.length - 2) === '.0') {
    return `${numberShortened.substring(0, numberShortened.length - 2)}K`;
  }
  return `${numberShortened}K`;
};

export const appendBodies = (body: any, fields: any) => {
  if (!fields) {
    return body;
  }
  const bodies = [body];
  for (let i = 1; fields[`body${i}`]; i += 1) {
    bodies.push(fields[`body${i}`]);
  }
  return bodies.join('\n');
};

export function formatCar(car: any) {
  const {
    year: { value: year },
    make: { value: make },
    model: { value: model },
    trim: { value: trim },
  } = car;
  return [year, make, model, trim].filter((x) => !isNil(x)).join(' ');
}

export function formatAddress(address: any) {
  return `${get(address, 'streetLine1')}, ${get(address, 'city')} ${get(
    address,
    'zip'
  )}`;
}
export function formatAddressShort(address: any) {
  return `${address.streetLine1} - ${address.state}`;
}

export function formatBytes(bytes: any) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';

  const BYTE_SIZE = 1000;
  const i = +Math.floor(Math.log(bytes) / Math.log(BYTE_SIZE));
  const strictSize = BYTE_SIZE ** i;

  return `${Math.round(bytes / strictSize)} ${sizes[i]}`;
}

export function serializeConsumerPreferredTime({ date, hour }: any) {
  return `${date.format('YYYY-MM-DD')} ${hour}`;
}

export function deserializeConsumerPreferredTime(preferredTime: string) {
  const dateFormat = moment(preferredTime).format('YYYY-MM-DD LT').split(' ');

  return {
    date: moment(dateFormat[0], 'YYYY-MM-DD'),
    hour: `${dateFormat[1]} ${dateFormat[2]}`,
  };
}

export function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
}

export function formatPhoneNumberKebab(phoneNumberString: string) {
  if (!phoneNumberString) {
    return '';
  }

  const digits = phoneNumberString.replace(/\D*/g, '');
  const first = digits.substr(0, 3);
  const second = digits.substr(3, 3);
  const third = digits.substr(6, 4);
  const formatted = `${first}${digits.length > 3 ? '-' : ''}${second}${
    digits.length > 6 ? '-' : ''
  }${third}`;

  return formatted;
}
